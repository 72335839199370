import { platonImageUrl } from "@Platon/core/helpers"

export default {
	computed: {
		remoteFileId() {
			if (this.remote) {
				return this.remote.id
			}

			if (this.remoteFile) {
				return this.remoteFile.id
			}

			if (this.item && Array.isArray(this.item.data) && this.item.data.length > 0) {
				return this.item.data[0].id
			}

			return null
		},

		remoteFileUrl() {
			if (this.remoteFileId) {
				return platonImageUrl(this.remoteFileId, 520, 520)
			}

			return null
		}
	},

	watch: {
		remoteFileUrl: {
			immediate: true,
			handler(url) {
				this.$nextTick(() => {
					if (this.$refs.image && url) {
						this.$refs.image.style.backgroundImage = `url('${url}')`
					}
				})
			}
		}
	}
}
